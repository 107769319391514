import { useVisualStableUpdate, VideoTrack } from '@livekit/components-react'
import moment from 'moment'
import momentTz from 'moment-timezone'
import 'moment/locale/uk'
import React from 'react'
import { LayoutProps } from './common'

moment.locale('uk')
momentTz.tz.setDefault('Europe/Kyiv')

const getTimestamp = () => {
  const now = new Date()
  const timeGet = moment(now)
  return timeGet.format('DD MMM YYYY HH:mm:ss:SSS Z')
}

const SingleSpeakerTimestampedLayout = ({ tracks: references }: LayoutProps) => {

  const [timestamp, setTimestamp] = React.useState<string>(getTimestamp())

  React.useEffect(() => {
    setTimeout(() => {
      setTimestamp(() => getTimestamp())
    }, 1000 / 15)
  }, [timestamp])

  const sortedReferences = useVisualStableUpdate(references, 1)
  if (sortedReferences.length === 0) {
    return null
  }
  return <>
    <span style={{
      position: 'absolute', float: 'right', fontFamily: 'monospace', backgroundColor: '#000000', padding: '3px',
    }}
    >
      {timestamp}
    </span>
    <VideoTrack {...sortedReferences[0]} />
  </>
}

export default SingleSpeakerTimestampedLayout
